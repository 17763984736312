/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'api';
import { LoadingService } from 'loading';
import { NotificationService } from 'notification';
import { PusherEvent, WebsocketService } from 'websocket';
import { UiFormDialogComponent } from '../../../../../../../components/ui/form-dialog/src/lib/ui-form-dialog.component';

import { AuthService } from 'auth';
import { formlyValidation } from 'formly';
import { PinState } from '../../pin/store/pin.state';
import { QuestionState } from './question.state';
import {
  IQuestionType,
  IQuestionTypeDetail,
  ReferEmailRequest,
} from './question.type';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuestionResolver {
  public readonly websocketService = inject(WebsocketService);
  public readonly notificationService = inject(NotificationService);
  public readonly loadingService = inject(LoadingService);
  public readonly apiService = inject(ApiService);
  public readonly pinState = inject(PinState);
  public readonly dialog = inject(MatDialog);
  public readonly router = inject(Router);
  public readonly state = inject(QuestionState);
  public readonly authService = inject(AuthService);

  public backToPin() {
    this.router.navigate(['/pin']);
    this.pinState.staff().data.set(undefined);
  }

  public hasPin(redirectTo: string) {
    if (!this.pinState.staff().data()) {
      this.router.navigateByUrl(redirectTo);

      return false;
    }

    return true;
  }

  public questionTypeQuery(
    job_id: number,
    staff_id: number,
    staff_type: string,
  ) {
    const model = this.state.questionTypeModel.clone();

    model.setCondition('whereHas', {
      questions: {
        job: {
          value: job_id,
          operator: 'scope',
        },
      },
    });
    model.setCondition('with', [
      {
        relation: 'questions',
        queries: {
          fields: [
            'id',
            'question_type_id',
            'question',
            'options',
            'option_type',
          ],
          where: {
            job: {
              value: job_id,
              operator: 'scope',
            },
          },
        },
      },
      {
        relation: 'job_override',
        queries: {
          where: { job_id: job_id },
        },
      },
      {
        relation: 'answers',
        queries: {
          where: {
            'answers.job_id': job_id,
            'answers.staff_id': staff_id,
            'answers.staff_type': staff_type,
          },
          whereHas: {
            question: {
              job: {
                value: job_id,
                operator: 'scope',
              },
            },
          },
        },
      },
    ]);
    return model.getQuery();
  }

  public questionTypeDetailQuery(
    questionTypeId: number,
    staff_id: number,
    staff_type: string,
    job_id: number,
  ) {
    const model = this.state.questionTypeModel.clone();

    model.setCondition('id', questionTypeId);

    model.setCondition('with', [
      {
        relation: 'questions',
        queries: {
          fields: [
            'id',
            'question_type_id',
            'question',
            'options',
            'option_type',
          ],
          where: {
            job: {
              value: job_id,
              operator: 'scope',
            },
          },
        },
      },
      {
        relation: 'questions.answer',
        queries: {
          fields: [
            'answers.id',
            'answers.question_id',
            'answers.answer',
            'answers.retries',
          ],
          where: {
            'answers.staff_id': staff_id,
            'answers.staff_type': staff_type,
            'answers.job_id': job_id,
          },
        },
      },
    ]);

    return model.getQuery();
  }

  async getQuestionTypes(questionTypeId?: number) {
    this.loadingService.show();
    const job_id = this.authService.storage.get('job').id as number;

    try {
      const staff = this.pinState.pin();

      const { event, data } = await firstValueFrom(
        this.websocketService.query<{ QuestionType: IQuestionType[] }>(
          this.questionTypeQuery(
            job_id,
            Number(staff.value),
            staff.type === 'staff' ? 'stambridge' : 'contractor',
          ),
        ),
      );
      this.loadingService.hide();
      if (event === PusherEvent.ERROR) {
        this.notificationService.error(data.message as string);
        return null;
      }

      if (data.QuestionType) {
        const formatData = this.formatDataQuestionType(data.QuestionType);
        this.state.types.set(formatData);
        if (questionTypeId) {
          return formatData.find((item) => item().id === questionTypeId);
        }
        return null;
      }
      return null;
    } catch (e) {
      this.loadingService.hide();
      this.notificationService.error('An error occurred');
      return null;
    }
  }

  public getQuestionTypeDetail(questionTypeId: number) {
    try {
      this.loadingService.show();
      const staff = this.pinState.pin();
      const staffId = Number(staff.value);
      const staffType = staff.type === 'staff' ? 'stambridge' : 'contractor';
      const job_id = this.authService.storage.get('job').id as number;
      this.websocketService
        .query<{
          QuestionType: IQuestionTypeDetail;
        }>(this.questionTypeDetailQuery(questionTypeId, staffId, staffType, job_id))
        .subscribe(({ event, data }) => {
          this.loadingService.hide();

          if (event === PusherEvent.ERROR) {
            this.notificationService.error(data.message as string);
            return;
          }

          if (data.QuestionType) {
            this.state.questions.set(
              data.QuestionType?.questions?.map((item) => signal(item)) || [],
            );
          }
        });
    } catch (error) {
      this.loadingService.hide();
      this.notificationService.error('An error occurred');
      return;
    }
  }

  public questionMutation(
    job_id: number | undefined,
    staff_id: number,
    staff_type: string,
    questions: { id: number; answer: Array<string | number> }[],
  ) {
    const mutation = this.state.questionMutation.clone();

    mutation.setData({
      job_id,
      staff_id,
      staff_type,
      questions,
    });

    return mutation.getMutation();
  }

  async saveAnswers(
    questions: { id: number; answer: Array<string | number> }[],
    questionTypeId: number,
  ) {
    this.loadingService.show();
    const staff = this.pinState.pin();
    const staffId = Number(staff.value);
    const staffType = staff.type === 'staff' ? 'stambridge' : 'contractor';
    const job_id = this.authService.storage.get('job').id as number;

    try {
      const { event, data } = await firstValueFrom(
        this.websocketService.mutation(
          this.questionMutation(job_id, staffId, staffType, questions),
        ),
      );
      this.loadingService.hide();
      if (event === PusherEvent.ERROR) {
        this.notificationService.error(data.message);
        return null;
      }
      return await this.getQuestionTypes(questionTypeId);
    } catch (e) {
      this.loadingService.hide();
      this.notificationService.error('An error occurred');
      return null;
    }
  }

  public referEmail(data: ReferEmailRequest) {
    const dialog = this.dialog.open(UiFormDialogComponent, {
      width: '400px',
      autoFocus: false,
      data: {
        title: 'Refer Email',
        fields: [
          {
            key: 'email',
            type: 'input',
            defaultValue: 'patrick.bourke@stambridge.group',
            templateOptions: {
              type: 'text',
              placeholder: 'Email',
              label: 'Email',
              required: true,
            },
            validators: {
              email: formlyValidation.email,
            },
          },
          {
            key: 'reason',
            type: 'input',
            defaultValue: '',
            templateOptions: {
              type: 'text',
              placeholder: 'Reason',
              label: 'Reason',
              required: true,
            },
          },
        ],
      },
    });

    dialog.afterClosed().subscribe((model) => {
      if (!model) return;

      this.loadingService.show();
      const job_id = this.authService.storage.get('job').id as number;
      this.apiService
        .post('questions/refer', { ...data, ...model, job_id })
        .then(() => {
          this.notificationService.success('Refer email successfully');
        })
        .finally(() => this.loadingService.hide());
    });
  }

  formatDataQuestionType(questionType: IQuestionType[]) {
    return questionType.map((item) => {
      let staff_score = 0;
      const list_staff_retries: number[] = []; //get min retries of all answers
      if (item.answers.length > 0) {
        item.answers.forEach((answer) => {
          const question = item.questions.find(
            (question) => question.id === answer.question_id,
          );

          const selectCorrect = question?.options.find(
            (option) => option.isCorrect && option.value === answer.answer[0],
          );

          if (selectCorrect) {
            staff_score += 1;
          }

          //if have answer but retries is null, its mean staff not retry this question
          //so we need push 0 to list_staff_retries
          // another case push answer.retries to list_staff_retries
          if (answer) {
            if (answer.retries === null) {
              list_staff_retries.push(0);
            }
            if (answer.retries) {
              list_staff_retries.push(answer.retries);
            }
          }
        });
      }

      const real_pass_score =
        item.job_override && item.job_override.default_pass_score
          ? item.job_override.default_pass_score
          : item.default_pass_score;

      const real_retries =
        item.job_override && item.job_override.retries
          ? item.job_override.retries
          : item.retries;
      const real_show_failed_answers =
        item.job_override?.show_failed_answers != undefined
          ? item.job_override.show_failed_answers
          : item.show_failed_answers;
      return signal({
        ...item,
        // calculator data
        answers_count: item.answers.length,
        questions_count: item.questions.length,
        staff_score,

        // get min retries of all answers
        staff_retries:
          list_staff_retries.length > 0
            ? Math.min(...list_staff_retries)
            : undefined,
        real_pass_score,
        real_retries,
        real_show_failed_answers,
      });
    });
  }
}
