export interface IQuestionSelected {
  idQuestion: number | undefined;
  value: (number | string)[];
}

export interface IQuestionsData {
  id: number;
  question_type_id: number;
  question: string;
  options: IQuestionsDataOption[];
  option_type: string;
  answer: IQuestionsDataAnswer | null;
  isFinish?: boolean;
}

export interface IQuestionsDataOption {
  value: string | number;
  isCorrect: boolean;
}

export interface IQuestionsDataAnswer {
  id: number | undefined;
  question_id: number | undefined;
  answer: (string | number)[];
  retries?: number;
}

export enum QuestionOptionType {
  Single = 'single',
  Multiple = 'multiple',
  SelectCorrect = 'select_correct',
}
