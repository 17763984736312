import { Injectable, WritableSignal, computed, signal } from '@angular/core';
import {
  IQuestionsData,
  QuestionOptionType,
} from 'components/question/card/src/lib/question-card.type';
import { WebsocketMutation, WebsocketQuery } from 'websocket';
import {
  QUESTION_MUTATION,
  QUESTION_QUERY,
  QUESTION_TYPE_QUERY,
} from './question.query';
import { IAnswerResult, IQuestionType } from './question.type';

@Injectable({
  providedIn: 'root',
})
export class QuestionState {
  readonly types = signal<WritableSignal<IQuestionType>[]>([]);

  public readonly questionTypeModel = new WebsocketQuery(
    QUESTION_TYPE_QUERY.model,
    QUESTION_TYPE_QUERY.query.fields,
  );

  public readonly questionModel = new WebsocketQuery(
    QUESTION_QUERY.model,
    QUESTION_QUERY.query.fields,
  );
  // Current questions and type
  readonly questions = signal<WritableSignal<IQuestionsData>[]>([]);
  readonly questionType = signal<IQuestionType | null>(null);

  public readonly questionMutation = new WebsocketMutation(
    QUESTION_MUTATION.controller,
    QUESTION_MUTATION.method,
  );

  // Current result
  readonly result = computed(() => {
    const questions = this.questions();
    const totalQuestion = questions.length;

    const result = questions.reduce(
      (acc, question) => {
        if (question().answer && (question().answer?.answer?.length ?? 0 > 0)) {
          acc.answered += 1;

          //for case single answer
          const answer = question().answer?.answer[0];
          const selectCorrect = question().options.find(
            (option) => option.isCorrect && option.value === answer,
          );
          if (selectCorrect) {
            acc.correct += 1;
          }
        }
        if (question().option_type === QuestionOptionType.SelectCorrect) {
          acc.totalQuestionTypeCorrect += 1;
        }

        return acc;
      },
      {
        answered: 0,
        total: totalQuestion,
        correct: 0,
        totalQuestionTypeCorrect: 0,
      },
    );

    return {
      ...result,
      isCompleted: result.answered === totalQuestion && totalQuestion > 0,
    } as IAnswerResult;
  });

  readonly isShowResultZone = computed(() => {
    return (
      this.questionType() &&
      this.questionType()!.answers.length > 0 &&
      this.questionType()!.questions.length > 0 &&
      this.questionType()!.answers.length >=
        this.questionType()!.questions.length
    );
  });
  readonly isReview = signal(false)

  public resetAnswers() {
    this.questions.set([]);
    this.questionType.set(null);
  }
}
