import { QueryMultiple } from 'websocket';

export const QUESTION_TYPE_QUERY = {
  model: 'QuestionType',
  query: {
    fields: [
      'id',
      'name',
      'description',
      'retries',
      'default_pass_score',
      'show_failed_answers',
    ],
  } as QueryMultiple,
};

export const QUESTION_QUERY = {
  model: 'Question',
  query: {
    fields: ['id', 'question', 'question_type_id', 'options'],
  } as QueryMultiple,
};

export const QUESTION_MUTATION = {
  controller: 'Question',
  method: 'save',
};
